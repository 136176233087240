.form-wrapper {
  @screen lg {
    @apply
      pl-20;

    &.half-wrapper {
      @apply
        w-11/12;

      form {
        .input-wrapper {
          @apply
            w-2/5
            float-left
            min-w-0
            mr-4;
        }
      }
    }
  }

  .closing-section {
    @apply
      w-full
      mt-5;

    @screen lg {
      @apply
        w-4/5
        ml-2;
    }

    hr {
      @apply
        mb-5;
    }
  }
}
  
label.for-checkbox {
  @apply
    text-xs
    inline-block
    align-top
    w-2/3;

  line-height: 16px;

  a {
    @apply
      text-xs
      text-primary-magenta;
  }
}

.field-wrapper {
  @apply
    relative;

  input[type=checkbox] {
    @apply
      inline-block
      align-top
      mr-1;
  }

  span[role=alert] {
    @apply
      absolute
      left-0
      p-1
      bg-primary-magenta
      bg-opacity-95
      text-white
      italic
      text-xs
      z-10;

    top: 100%;

    &::before {
      content: '';
      @apply
        absolute
        w-0
        h-0
        top-0
        left-0.5
        transform
        -translate-y-1/2
        opacity-95;

      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #DB003D transparent;
    }
  }
}

.input-wrapper {
  @apply
    bg-white
    bg-opacity-90
    shadow-lg
    border-b
    border-primary-gray
    px-4
    py-3
    mb-4
    w-full
    relative;

  @screen sm {
    min-width: 30rem;
  }

  label {
    @apply
      text-xs
      block;
  }

  input,
  .input {
    @apply
      w-full
      font-semibold
      rounded-none
      outline-none;

    &.editable {
      @apply
        border-l
        border-primary-lgray
        px-1;

        &:focus {
          @apply
            border-transparent;
        }
    }
  }

  span[role=alert] {
    @apply
      absolute
      left-0
      right-0
      p-1
      bg-primary-magenta
      bg-opacity-95
      text-white
      italic
      text-xs
      z-10;

    top: 100%;
  }

  & + time {
    @apply
      italic
      text-xxs
      block
      pl-4
      text-primary-gray;
  }

  &.highlight {
    .input {
      @apply
        text-primary-magenta
        text-2xl;
    }
  }

  &.no-bg {
    @apply
      shadow-none
      border-primary-lgray
      border-dotted;
  }
}

.submit-wrapper {
  @apply
    text-center
    mt-10
    -mb-5
    transform
    translate-y-1/2;

  @screen sm {
    @apply
      -mb-10
      mt-20;
  }

  @screen lg {
    @apply
      -mb-20;
  }
}