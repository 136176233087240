.page-view.info {
  @apply
    relative
    pb-10
    pt-10
    overflow-hidden;

  .page-content {
    * {
      @apply
        text-lg;
    }

    hr {
      @apply
        mt-6;
    }

    h3 {
      @apply
        text-2xl
        my-5
        font-bold
        text-primary-magenta;
    }

    small {
      @apply
        text-sm
        italic
        mt-10
        inline-block;

      > * {
        @apply
          text-sm;
      }

      a {
        @apply
          underline;

        &:hover {
          @apply
            no-underline;
        }
      }
    }

    ol {
      @apply
        list-decimal
        pl-5;
    }

    ul {
      @apply
        list-disc
        pl-5;
    }
  }
}